export default function Configs() {
  const tableColumns = [
    {
      label: "名称",
      prop: "name",
      minWidth: 140,
      align: "left",
      show: true
    },
    {
      label: "类型",
      prop: "type",
      align: "center",
      show: true
    },
    {
      label: "排序",
      prop: "orderNum",
      align: "center",
      show: true
    },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    tableColumns
  };
}
