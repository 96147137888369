<template>
  <div class="single-bridge-common">
    <div class="mb-2">
      <el-button class="btn-primary" @click="goto()">新增</el-button>
    </div>
    <div class="table-wrapper" >
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          rowKey="id"
          v-loading="pageLoading"
          :stripe="false"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-edit mr-6" @click="goto(row)">编辑</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        :before-close="beforeDialogClose"
        :destroy-on-close="true"
        width="900px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="上级监测项" prop="parentId">
                <el-select v-model="form.model.parentId" placeholder="请选择">
                  <el-option label="root" :value="0"></el-option>
                  <el-option v-for="(parent,p) in pageData" :key="p" :label="parent.name" :value="parent.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称" prop="name">
                <el-input v-model="form.model.name"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="type">
                <el-input v-model="form.model.type"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="小类" prop="subType">
                <el-select v-model="form.model.subType" placeholder="请选择">
                  <el-option v-for="(sub,s) in subList" :key="s" :label="sub.name" :value="sub.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="排序" prop="orderNum">
                <el-input type="number" v-model="form.model.orderNum"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import MPage from "@/mixins/MPage.js";
  import MHandle from "@/mixins/MHandle.js";
  import Configs from "./config/index";
  let mPage = MPage({
    pagePath: "",
    pageDelPath: "/monitorDict/delete",
    pageDelsPath: "",
    treePath:"/monitorDict/getTreeList",
    pageParams: {},
  });
  let mHandle = MHandle({
    handleGetPath: "",
    handleInsertPath: "",
    handleUpdatePath: "",
    handleDownLoadPath: "",
    handleData: {},
  });
  export default {
    mixins: [mPage, mHandle],
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        dialogVisible: false,
        title:'',
        pageData:[{id:1,name:'te',type:1,orderNum:1,children: [{id:11,name:'tes'}]}],
        form:{
          model:{},
          rules:{
            parentId:[
              { required: true, message: '请选择上级监测项', trigger: 'blur' }
            ],
            name:[
              { required: true, message: '请填写', trigger: 'blur' }
            ]
          }
        },
        subList:[],
        props:{
          emitPath:false,
          value:'id',
          label:'name',
          children:'children'
        },
        isAdd:true
      };

    },
    created() {
      this.getSubType()
    },
    methods: {
      getSubType(){
        this.$http.get("/monitorDict/getSubTypeList").then(res => {
          if(res.success){
            if (res.data){
              let qyArry = Object.keys(res.data)
              let arr = []
              for (let item of qyArry){
                arr.push({name:res.data[item],value:item})
              }
              this.subList = arr
            }
          }
        })
      },
      goto(data) {
        this.form.model = {
          nameList: [{
            name: ''
          }]
        }
        if (data) {
          this.title = "编辑配置信息";
          this.isAdd = false
          this.getDetail(data.id)
        } else {
          this.title = "新增配置信息";
          this.isAdd = true
          this.dialogVisible = true;
        }
      },
      getDetail(id){
        this.$http.get('/monitorDict/getById',{params:{id:id}}).then(res => {
          if (res.success){
            this.form.model = res.data
            this.dialogVisible = true;
          }
        })
      },
      beforeDialogClose(done) {
        done();
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let api = ''
            if (this.isAdd === true){
              api = "/monitorDict/add"
            } else {
              api = "/monitorDict/update"
            }
            this.$http.post(api,this.form.model).then(res=>{
              if(res.success){
                this.$message.success("操作成功");
                this.pageGet(true)
                this.dialogVisible = false;
              } else {
                this.$message.error(res.msg)
              }
            });
          }
        })
      },
      resetFields() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style scoped>
  .form-flex{
    display: flex;
  }
  .form-item-btn{
    width: 100px;
    margin-left: 10px;
  }
</style>